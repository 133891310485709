
// Set your brand colors
$purple: #8A4D76; 
$pink: #FA7C91;
$brown: #757763;
$beige-light: #D0D1CD;
$beige-lighter: #EFF0EB;

// Update Bulma's global variables
$grey-dark: #dddddd;
$grey-light: #dddddd;
$primary-light: #414141; 
$link: $beige-light;

// Update some of Bulma's component variables
$body-background-color: #1f2022;
$body-color: #dddd;

@import 'bulma/bulma.sass';

.category-title {
  color: $primary;
  text-align: right;
  font-weight: bold;

  @include mobile {
    text-align: left;
  }
}

a {
  font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
  color: $grey-dark !important;
}