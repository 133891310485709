@import 'bulma/bulma.sass';

.category-title {
  color: $primary;
  text-align: right;
  font-weight: bold;

  @include mobile {
    text-align: left;
  }
}
